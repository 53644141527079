import React, { useRef, useContext, useState, useEffect, useCallback } from 'react';
import DataGrid, { Column, Editing, Paging, Pager, Toolbar, Item } from 'devextreme-react/data-grid';
import './UsersPage.css';
import { BUSINESS_ID } from '../constants/storageKeys';
import { Button, LoadIndicator, TagBox } from 'devextreme-react';
import { inject, observer } from 'mobx-react';
import NavButtons from '../components/common/NavButtons';

const rolesOptions = [
  "Owner",
  "Manager",
  "Optometrist",
  "Front Desk"
];

const RoleTagBoxEditor = (props) => {
  return (
    <TagBox
      {...props}
      dataSource={rolesOptions}
      defaultValue={props.data.value}
      onValueChanged={(e) => props.data.setValue(e.value)}
      showClearButton={true}
      searchEnabled={true}
      displayExpr={(item) => item}
      valueExpr={(item) => item}
      showSelectionControls={true}
      applyValueMode="useButtons"
      multiline={false}
    />
  );
};

const UsersPage = inject('userStore')(observer(({ userStore, clinicId }) => {
  const isMounted = useRef(true);
  const [ready, setReady] = useState(false);
  const [users, setUsers] = useState([]);
  const dataGridRef = useRef(null);

  useEffect(() => {
    userStore.refresh().then(() => {
      setUsers(userStore.data);
      setReady(true);
    });

    return () => {
      isMounted.current = false;
    }
  }, []);  // eslint-disable-line

  const onRowInserting = useCallback(async (e) => {
    try {
      const newUser = await userStore.add(e.data);
      setUsers([...users]);
    } catch (error) {
      console.error('Failed to add user', error);
      e.cancel = true; // Prevents the DataGrid from updating its state
    }
  }, [users]);

  const onRowUpdating = useCallback(async (e) => {
    try {
      const updatedUser = await userStore.update({ ...e.oldData, ...e.newData });

      setUsers(users.map(p => p.id === updatedUser.id ? updatedUser : p));
    } catch (error) {
      console.error('Failed to update user', error);
      e.cancel = true; // Prevents the DataGrid from updating its state
    }
  }, [users]);

  const onRowRemoving = useCallback(async (e) => {
    try {
      await userStore.delete(e.data.id);
      setUsers(users.filter(p => p.id !== e.data.id));
    } catch (error) {
      console.error('Failed to delete user', error);
      e.cancel = true; // Prevents the DataGrid from updating its state
    }
  }, [users]);

  const onInitNewRow = useCallback((e) => {
    e.data.isActive = true;
    e.data.isDoctor = false;
    e.data.businessId = window.localStorage.getItem(BUSINESS_ID);
  }, []);

  const onRowDblClick = useCallback((e) => {
    e.component.editRow(e.rowIndex);
  }, []);

  const onAddRow = (e) => {
    dataGridRef.current.instance.addRow();
  };

  const isNew = useCallback((e) => {
    return !(e.row.data["isMigrated"]);
  }, []);

  return (
    <div className='user-page mt-2 p-2'>
      <h3>Confirm your Users</h3>
      {
        ready ? <>
          <DataGrid
            ref={dataGridRef}
            allowColumnResizing={true}
            dataSource={users}
            keyExpr="id" // Unique key field of your data object
            showBorders={true}
            onRowInserting={onRowInserting}
            onRowUpdating={onRowUpdating}
            onRowRemoving={onRowRemoving}
            onInitNewRow={onInitNewRow}
            rowAlternationEnabled={true}
            onRowDblClick={onRowDblClick}
          >
            <Paging enabled={true} />
            <Pager showPageSizeSelector={false} />

            <Editing
              mode="row" // 'row', 'form', 'batch' are also available
              allowAdding={true}
              allowUpdating={true}
              allowDeleting={isNew}
              useIcons={true}
            />
            <Toolbar>
              <Item>
                <Button icon='add' text="Add User" onClick={onAddRow}></Button>
              </Item>
            </Toolbar>
            {/* Define columns based on your data fields */}
            <Column width="70" dataField="isActive" caption="Active" dataType="boolean" />
            <Column width="150" dataField="firstName" caption="First Name" />
            <Column width="150" dataField="lastName" caption="Last Name" />
            <Column width="200" dataField="emailAddress" caption="Email" />
            <Column visible={false} width="70" dataField="isDoctor" caption="Doctor" dataType="boolean" />
            <Column width="80" dataField="licenseNumber" caption="License No." />
            <Column width="80" dataField="publicInsuranceProviderNumber" caption="Billing No." />

            <Column width="320"
              dataField="roles"
              caption="Role(s)"
              editCellComponent={RoleTagBoxEditor}
            />
          </DataGrid>
          <div style={{ marginTop: "40px", width: "1000px" }}>
            <NavButtons currentRoute='/users' />
          </div>
        </> : <LoadIndicator height={40} width={40} />
      }
    </div>
  );
}));

export default UsersPage;