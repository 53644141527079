import React, { useRef, useState, useEffect, useCallback } from 'react';
import DataGrid, { Column, Editing, Paging, Pager, Lookup, Toolbar, Item } from 'devextreme-react/data-grid';
import { observer, inject } from 'mobx-react';
import './ServicesPage.css';
import NavButtons from '../components/common/NavButtons';
import { Button } from 'devextreme-react';

const examOptions = [
  { id: 'Full', name: 'Full' },
  { id: 'Partial', name: 'Partial' },
  { id: '', name: 'N/A' } // Assuming you want to display an option for no selection
];

const ServicesPage = inject('serviceStore')(observer(({ serviceStore, clinicId }) => {
  const isMounted = useRef(true);
  const [ready, setReady] = useState(false);
  const [publicServices, setPublicServices] = useState([]);
  const [privateServices, setPrivateServices] = useState([]);
  const dataGridRef = useRef(null);

  const loadData = async () => {
    await serviceStore.refresh(clinicId);
    setPublicServices(serviceStore.publicData);
    setPrivateServices(serviceStore.privateData);
    setReady(true);
  }
  useEffect(() => {
    loadData();

    return () => {
      isMounted.current = false;
    }
  }, []);  // eslint-disable-line

  const onRowInserting = useCallback(async (e) => {
    try {
      await serviceStore.add(e.data);
      loadData();
    } catch (error) {
      console.error('Failed to add service', error);
      e.cancel = true; // Prevents the DataGrid from updating its state
    }
  }, []);

  const onRowUpdating = useCallback(async (e) => {
    try {
      await serviceStore.update({ ...e.oldData, ...e.newData });
      loadData();
    } catch (error) {
      console.error('Failed to update service', error);
      e.cancel = true; // Prevents the DataGrid from updating its state
    }
  }, [publicServices, privateServices]);

  const onRowRemoving = useCallback(async (e) => {
    try {
      await serviceStore.delete(e.data.id);
      loadData();

      // setPublicServices(publicServices.filter(p => p.id !== e.data.id));
    } catch (error) {
      console.error('Failed to delete service', error);
      e.cancel = true; // Prevents the DataGrid from updating its state
    }
  }, [publicServices, privateServices]);

  const onInitNewRow = useCallback((isPublic, e) => {
    e.data.isActive = true;
    e.data.clinicId = clinicId;
    e.data.isBookable = true;
    e.data.isRepeatable = false;
    e.data.sendReminder = false;
    e.data.isSubsidized = isPublic;
    e.data.isMigrated = false;
  }, []);

  const onRowDblClick = useCallback((e) => {
    e.component.editRow(e.rowIndex);
  }, []);

  const onAddRow = (e) => {
    dataGridRef.current.instance.addRow();
  };

  const isNew = useCallback((e) => {
    return !(e.row.data["isMigrated"]);
  }, []);

  const handleEditorPreparing = (e) => {
    if (e.dataField === 'code') { // Target the "Code" column
      const isMigrated = e.row.data.isMigrated;
      if (isMigrated) {
        e.editorOptions.disabled = true; // Disable editing
      }
    }
  };

  return (
    <div className='service-page mt-2 p-2'>
      <h3>Confirm your Service Codes</h3>
      <div style={{ position: 'relative', paddingTop: "1em" }}>
        <h4>OHIP Services</h4>
        <DataGrid
          allowColumnResizing={true}
          dataSource={publicServices}
          keyExpr="id" // Unique key field of your data object
          showBorders={true}
          onRowUpdating={onRowUpdating}
          rowAlternationEnabled={true}
          onRowDblClick={onRowDblClick}
        >
          <Paging enabled={true} />
          <Pager showPageSizeSelector={false} />
          <Editing
            mode="row" // 'row', 'form', 'batch' are also available
            allowAdding={false}
            allowUpdating={true}
            allowDeleting={false}
            useIcons={true}
          />
          <Column allowSorting={false} width="80" dataField="isActive" visible={false} allowEditing={false} caption="Active" dataType="boolean" />
          <Column allowSorting={false} width="100" dataField="code" allowEditing={false} caption="Code" />
          <Column allowSorting={false} width="300" dataField="name" allowEditing={false} caption="Description" />
          <Column allowSorting={false} width="80" dataField="fee" allowEditing={false} caption="Fee" dataType='number' format={{ type: 'currency', currencty: 'USD', precision: 2 }} />
          <Column allowSorting={false} width="100" dataField="durationInMinutes" caption="Duration (min)" dataType='number' />
          <Column allowSorting={false} width="80" dataField="isBookable" visible={false} allowEditing={false} caption="Bookable" dataType="boolean" />
          <Column allowSorting={false} width="80" dataField="isRepeatable" visible={false} allowEditing={false} caption="Repeatable" dataType="boolean" />
          <Column allowSorting={false} width="100" dataField="examScope" visible={false} caption="Exam" allowEditing={false} />
          <Column allowSorting={false} width="120" dataField="recallPeriodInMonth" caption="Recall (months)" allowEditing={true} />
          <Column allowSorting={false} width="120" dataField="sendReminder" caption="Send Reminder" dataType="boolean" />
        </DataGrid>
      </div>
      <h1 style={{ height: '2em' }}></h1>
      <div style={{ position: 'relative' }}>
        <h4 className='service-grid-header'>Non-OHIP Services</h4>
        <DataGrid
          ref={dataGridRef}
          allowColumnResizing={true}
          dataSource={privateServices}
          keyExpr="id" // Unique key field of your data object
          showBorders={true}
          onRowInserting={onRowInserting}
          onRowUpdating={onRowUpdating}
          onRowRemoving={onRowRemoving}
          onInitNewRow={(e) => onInitNewRow(false, e)}
          rowAlternationEnabled={true}
          onRowDblClick={onRowDblClick}
          onEditorPreparing={handleEditorPreparing}
        >
          <Paging enabled={true} />
          <Pager showPageSizeSelector={false} />
          <Editing
            mode="row" // 'row', 'form', 'batch' are also available
            allowAdding={true}
            allowUpdating={true}
            allowDeleting={isNew}
            useIcons={true}
          />
          <Toolbar>
            <Item>
              <Button icon='add' text="Add Service" onClick={onAddRow}></Button>
            </Item>
          </Toolbar>

          <Column width="80" dataField="isActive" caption="Active" dataType="boolean" />
          <Column width="100" dataField="code" caption="Code" allowEditing />
          <Column width="300" dataField="name" caption="Description" />
          <Column width="80" dataField="fee" caption="Fee" dataType='number' />
          <Column width="100" dataField="durationInMinutes" caption="Duration (min)" dataType='number' />
          <Column width="80" dataField="isBookable" caption="Bookable" dataType="boolean" />
          <Column width="80" dataField="isRepeatable" caption="Repeatable" dataType="boolean" />
          <Column width="100" dataField="examScope" caption="Exam">
            <Lookup dataSource={examOptions} displayExpr="name" valueExpr="id" />
          </Column>
          <Column width="120" dataField="recallPeriodInMonth" caption="Recall (months)" />
          <Column width="120" dataField="sendReminder" caption="Send Reminder" dataType="boolean" />
        </DataGrid>
      </div>
      <div style={{ marginTop: "40px", width: "1000px" }}>
        <NavButtons currentRoute='/services' />
      </div>
    </div>
  );
}));

export default ServicesPage;